@font-face {
    font-family: 'Ubuntu';
    src: local('Ubuntu'), url('../fonts/Ubuntu/Ubuntu-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
  
@font-face {
    font-family: 'Grand Hotel';
    src: local('Grand Hotel'), url('../fonts/GrandHotel/GrandHotel-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
