.navbar {
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
}

.navbar-fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

.nav-link {
    margin-right: 10px;
}

.navbar-brand {
    font-weight: bold;
    color: #333;
}

.nav-item .fa-sign-out-alt {
    margin-left: 5px;
}

.user-name {
    margin-right: 20px;
}

.nav-link-btn {
    position: relative;
    display: inline-block;
}

.nav-link-btn::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: black;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}

.nav-link-btn:hover::after {
    visibility: visible;
    width: 100%;
}