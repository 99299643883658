.card-img-text {
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
    font-size: 0.65em;
  }
  
  .cards-text {
    font-size: 0.95em;
  }
  
  .card-row {
    overflow: hidden;
  }
  
  .card-description-body {
    background-color: rgb(255, 253, 249);
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: 0.27s ease-in;
  }
  
  .cards-body {
    /* padding: 1em !important; */
    height: 100%;
    width: 100%;
    background-color: white;
  }
  
  .cards-footer {
    font-size: 0.6em;
    padding: 0 0.5em !important;
    background-color: rgba(240,240,240,1);
  }
  
  .cards-footer > .row > .col:not(:last-child) {
    border-right: solid 1px rgba(200,200,200,1);
  }
  
  .lunch-card-img {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  
  .lunch-card-img img {
    width: 9em;
    height: 9em;
    border-radius: 0.3em;
    padding: 0.5em;
    object-fit: contain;
    background-color: rgba(255, 255, 255, 1);
  }

  .like-button {
      color: Grey;
  }

  .like-button.liked {
      color: Tomato;
  }

  .card-description-body {
    background-color: rgba(var(--bs-lunchdark-rgb));
    border-right: 1em solid rgba(var(--bs-lunchdark-rgb));
  }


  /* Media query for small screens */
@media screen and (max-width: 768px) {

  .cards-body {
    border-left: 1em solid rgba(var(--bs-lunchdark-rgb));
  }

  .card-restaurant-name-header {
      margin: 0;
      padding: 0;
      margin-left: 1em;
  }

  .cards-footer {
    padding: 0.2em 0.5em !important;
  }

}
