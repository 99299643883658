.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
    max-width: 600px;
    margin: 100px auto;
}

.modal-header {
    padding: 20px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.modal-title {
    font-size: 24px;
    color: #333;
}

.btn-close {
    font-size: 24px;
    color: #888;
}

.modal-body {
    padding: 20px;
}

.restaurant-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.logo-container {
    width: 120px;
    height: 120px;
    overflow: hidden;
    border-radius: 0.3em;
    margin-right: 20px;
    background-color: white;
    border: 2px solid var(--bs-primary);
}

.logo-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.3em;
    padding: 5px;
}

.info-details {
    flex: 1;
}

.address, .contact-info {
    margin-bottom: 10px;
}

.default-price {
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.standing-message p {
    color: #555;
    font-style: italic;
}
