.wave-icon-container {
    position: relative;
    height: 6rem; /* Adjust the height as needed */
}

.wave-icon {
    position: absolute;
    font-size: 2.8rem;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.3; /* Default low opacity */
    color: white;
}

.wave-icon:nth-child(1) {
    animation: wave 0.9s infinite;
}

.wave-icon:nth-child(2) {
    animation: wave 0.9s infinite 0.3s;
}

.wave-icon:nth-child(3) {
    animation: wave 0.9s infinite 0.6s;
}

@keyframes wave {
    0%, 100% {
        opacity: 0.3;
    }
    40% {
        opacity: 1;
    }
}


#down-arrow-button, #down-arrow-button:focus, #down-arrow-button:active {
    border: none;
}