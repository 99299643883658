/* styles.css */

:root {
    --var-background-color: rgb(142, 167, 98);
    --var-item-background-color: rgb(117, 139, 78);
    --var-text-color: rgb(254, 255, 239);
    --var-title-color: rgb(254, 255, 239);
    --bs-body-bg: #fbfbe7;
    --var-body-font-family: 'Ubuntu';
    --var-header-background-color: rgb(107 125 43);
    --var-menu-container-background: rgb(107 125 43);
    --var-header-color: rgb(253, 255, 247);
    --var-header-font-family: 'Grand Hotel', cursive;
}

 /* .btn-primary {
  border-width: 3px !important;
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #ff7600 !important;
  --bs-btn-border-color: #ff7600 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #bd5902 !important;
  --bs-btn-hover-border-color: #bd5902 !important;
  --bs-btn-focus-shadow-rgb: 60,153,110 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #bd5902 !important;
  --bs-btn-active-border-color: #bd5902 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #cc9769 !important;
  --bs-btn-disabled-border-color: #cc9769 !important;
}  */

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, a, span, button, p {
  font-family: var(--var-body-font-family);
}

.btn {
  font-family: var(--var-body-font-family) !important;
}

html, body, #root, #app {
  height: 100%;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

html {
  height: 100%;
  width: 100%;
  font-family: var(--var-body-font-family);
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  /* background-color: rgb(20, 20, 20) !important; */
  font-family: var(--var-body-font-family);
}

.top-auto {
  top: auto!important;
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.react-cookie-law-accept-all-btn {
  --bs-btn-color: #fff!important;
  --bs-btn-bg: #198754!important;
  --bs-btn-border-color: #198754!important;
  --bs-btn-hover-color: #fff!important;
  --bs-btn-hover-bg: #157347!important;
  --bs-btn-hover-border-color: #146c43!important;
  --bs-btn-focus-shadow-rgb: 60, 153, 110!important;
  --bs-btn-active-color: #fff!important;
  --bs-btn-active-bg: #146c43!important;
  --bs-btn-active-border-color: #13653f!important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125)!important;
  --bs-btn-disabled-color: #fff!important;
  --bs-btn-disabled-bg: #198754!important;
  --bs-btn-disabled-border-color: #198754!important;
  --bs-btn-padding-x: 0.75rem!important;
  --bs-btn-padding-y: 0.375rem!important;
  --bs-btn-font-size: 1rem!important;
  --bs-btn-font-weight: 400!important;
  --bs-btn-line-height: 1.5!important;
  --bs-btn-border-width: var(--bs-border-width)!important;
  --bs-btn-border-radius: var(--bs-border-radius)!important;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075)!important;
  --bs-btn-disabled-opacity: 0.65!important;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5)!important;
  display: inline-block!important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x)!important;
  font-family: var(--bs-btn-font-family)!important;
  font-size: var(--bs-btn-font-size)!important;
  font-weight: var(--bs-btn-font-weight)!important;
  line-height: var(--bs-btn-line-height)!important;
  color: var(--bs-btn-color)!important;
  text-align: center!important;
  text-decoration: none!important;
  vertical-align: middle!important;
  cursor: pointer!important;
  -webkit-user-select: none!important;
  user-select: none!important;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color)!important;
  border-radius: var(--bs-btn-border-radius)!important;
  background-color: var(--bs-btn-bg)!important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;

}

.react-cookie-law-accept-selection-btn {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #6c757d !important;
  --bs-btn-border-color: #6c757d !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #5c636a !important;
  --bs-btn-hover-border-color: #565e64 !important;
  --bs-btn-focus-shadow-rgb: 130, 138, 145 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #565e64 !important;
  --bs-btn-active-border-color: #51585e !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #6c757d !important;
  --bs-btn-disabled-border-color: #6c757d !important;
  --bs-btn-padding-x: 0.75rem !important;
  --bs-btn-padding-y: 0.375rem !important;
  --bs-btn-font-size: 1rem !important;
  --bs-btn-font-weight: 400 !important;
  --bs-btn-line-height: 1.5 !important;
  --bs-btn-border-width: var(--bs-border-width) !important;
  --bs-btn-border-radius: var(--bs-border-radius) !important;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075) !important;
  --bs-btn-disabled-opacity: 0.65 !important;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5) !important;
  display: inline-block !important;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x) !important;
  font-family: var(--bs-btn-font-family) !important;
  font-size: var(--bs-btn-font-size) !important;
  font-weight: var(--bs-btn-font-weight) !important;
  line-height: var(--bs-btn-line-height) !important;
  color: var(--bs-btn-color) !important;
  text-align: center !important;
  text-decoration: none !important;
  vertical-align: middle !important;
  cursor: pointer !important;
  -webkit-user-select: none !important;
  user-select: none !important;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color) !important;
  border-radius: var(--bs-btn-border-radius) !important;
  background-color: var(--bs-btn-bg) !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}