// custom.scss

// Import Bootstrap’s functions, variables, and mixins first
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

// Then import your variables file
@import './variables';

// Override Bootstrap's default theme-colors

// Create your own map
$custom-colors: (
  'primary': rgb(30, 114, 144), // Your custom primary color
  'success': rgb(33, 125, 61), // Your custom success colo
  'lunchdark': #374245, // Your custom secondary color
  'gray-100': #f8f9fa,
  'gray-200': #e9ecef,
  'gray-300': #dee2e6,
  'gray-400': #ced4da,
  'gray-500': #adb5bd,
  'gray-600': #6c757d,
  'gray-700': #495057,
  'gray-800': #343a40,
  'gray-900': #212529,
);

$font-family-base: 'Ubuntu';

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);


// Finally, import the rest of Bootstrap
@import '~bootstrap/scss/bootstrap';

// You can also add other custom styles here
