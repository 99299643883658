@keyframes shake1 {
  0% { transform: rotate(0); scale: 1;}
  10% { transform: rotate(0); scale: 1; }
  25% { transform: rotate(-5deg); }
  30% { transform: rotate(5deg); }
  35% { transform: rotate(-5deg); }
  40% { transform: rotate(5deg); scale: 1.2;}
  55% { transform: rotate(0); }
  100% { transform: rotate(0); scale: 1;}
}

@keyframes shake2 {
  0% { transform: rotate(0); scale: 1;}
  10% { transform: rotate(0); scale: 1; }
  25% { transform: rotate(5deg); }
  30% { transform: rotate(-5deg); }
  35% { transform: rotate(5deg); }
  40% { transform: rotate(-5deg); scale: 1.2;}
  55% { transform: rotate(0); }
  100% { transform: rotate(0); scale: 1;}
}

@keyframes pulse_header {
  0% { transform: scale(1); }
  25% { transform: scale(1.01); }
  75% { transform: scale(1.01); }
  100% { transform: scale(1); }
}

.header {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent !important;
  transform-origin: center;
}

.custom-header .display-1 {
  transform-origin: center;
  animation: pulse_header 3s ease-in infinite;
}

.fa-fork,
.fa-knife {
  font-size: 0.7em;
  transform-origin: center;

  /* Apply the animation */
  /* animation: shake 0.5s ease-in-out infinite; */
}

.fa-fork {
  clip-path: polygon(0% 0%, 50% 0%, 50% 100%, 0% 100%);
  margin-right: 10px;
  /* Adjust the origin of the rotation */
  transform-origin: 25% 50%;
  animation: shake1 3s ease-in infinite;
}

.fa-knife {
  clip-path: polygon(54% 0%, 100% 0%, 100% 100%, 50% 100%);
  margin-left: 10px;
  /* Adjust the origin of the rotation */
  transform-origin: 75% 50%;
  animation: shake2 3s ease-in infinite;

}

@media (max-width: 768px) {
  .header {
    font-size: 4rem;
  }
}