@keyframes flyInFromLeft {
    0% {
        opacity: 0;
        transform: scaleY(-0.5);
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}

@keyframes pulse {
    0% {
      transform: scale(1);
    }
    20% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
}

.start-page-first-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
  
.start-page-first-section::before {
    content: "";
    position: absolute;
    top: 0; left: 0;
    width: 100%; 
    height: 135vh;
    background-image: url('/public/images/bg-hands-making-food.jpg');
    background-size: cover;
    background-position: center;
    filter: saturate(2) blur(3px) brightness(0.6);;
}

.custom-header {
    position: sticky;
    top: 0;
    background-color: transparent !important;
    color: var(--var-header-color);
    z-index: 100; 
}

.custom-header h1 {
    font-family: var(--var-header-font-family);  
}

@media (max-width: 768px) {
  .custom-header {
    position: fixed;
  }
}

.menu-container {
    padding: 0;
    margin: 0;
    border-radius: 8px;
}

/* Each menu item */
.menu-item {
    background-color: transparent;
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.2), 5px 4px 16px 0px rgba(0,0,0,0.2);
    padding: 0;
    border-radius: 0.3em;
    overflow: hidden;
    opacity: 0;
    animation: flyInFromLeft 0.5s ease-out forwards;
    animation-delay: calc(var(--i) * 0.05s);
}
  
.lunch-list-container {
    position: relative;
    padding: 1rem;
    /* background-color: rgb(255 253 246); */
    background-color: #faf9f6;
    border-radius: 5em 5em 0 0;
    margin-top: 0;
    min-height: 100vh;
}

.alive-icon {
    animation: pulse 2.5s infinite;
}

.lunch-card-container {
    position: relative;
    transition: transform 0.2s ease;
    border: 5px transparent;
}

.lunch-card-container:hover {
    cursor: pointer;
    transform: translateY(-3px);
}
/* .lunch-card-container:hover > div > div > div > .card-description-body {
    background-color: white!important;
} */
