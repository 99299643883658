.rl-divider {
    position: relative; /* Ensure relative positioning for the parent element */
}

.rl-divider:before {
    content: ''; /* Ensure content is empty for pseudo-element */
    position: absolute; /* Set absolute positioning for the pseudo-element */
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px; /* Adjust the width of the divider */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); /* Define gradient */
}

.rl-divider:after {
    content: ''; /* Ensure content is empty for pseudo-element */
    position: absolute; /* Set absolute positioning for the pseudo-element */
    top: 0;
    bottom: 0;
    left: 0;
    width: 1px; /* Adjust the width of the divider */
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); /* Define gradient */
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
    color: inherit;
    text-decoration: inherit;
}